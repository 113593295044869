import { Component, FunctionComponent } from "react";
import { Route, Routes } from "react-router-dom";
import H5Layout from "../layout/H5Layout";
import MainLayout from "../layout/MainLayout";
import home from "../pages/h5/home/index";



export type PageRoute = {

    path: string,
    layout?: any | Component | FunctionComponent,
    component?: any | Component | FunctionComponent,
    children?: PageRoute[]

}


export const renderRoute = (route: PageRoute, index: number) => {

    const hasChildren = (route.children && route.children.length > 0) ? true : false;
    const children: PageRoute[] | undefined = route.children;
    const Layout: any = route.layout ? route.layout : <></>;
    const RouteComponent: any = route.component ? route.component : <></>;
    return (
        <Route key={route.path} path={route.path} element={

            hasChildren ? <Layout>
                <Routes>
                    {children?.map((it, i) => renderRoute(it, i))}
                </Routes>
            </Layout> : <RouteComponent />
        } >
        </Route>
    )


}

export const rouets: PageRoute[] = [
    {
        path: "/*",
        layout: MainLayout,
        children: [
            {
                path: "/h5/*",layout:H5Layout,children:[
                    {path:"/home",component:home}
                ]
            }
        ]
    },


];


