import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes,HashRouter } from 'react-router-dom';
import { renderRoute, rouets } from './config/routes';

function App() {

  useEffect(()=>{

    document.documentElement.setAttribute(
      'data-prefers-color-scheme',
      'dark'
    )

  },[]);

  return (
    <>
      <HashRouter>
        <Routes>
          {rouets.map((route, i) => renderRoute(route, i))}
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
