// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
html, body {
  margin: 0;
  padding: 0;
  height: 100vh; /* 设置视口高度 */
  overflow: hidden;
}

:root:root {
  --adm-color-primary: #ff9119;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EAEI,SAAA;EACA,UAAA;EACA,aAAA,EAAA,WAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,4BAAA;AAAJ","sourcesContent":["// @import '~antd-mobile/es/global';\nhtml,body{\n \n    margin: 0;\n    padding: 0;\n    height: 100vh; /* 设置视口高度 */\n    overflow: hidden;\n}\n\n:root:root {\n    --adm-color-primary: #ff9119;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
