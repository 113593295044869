import React, { createContext, FC, useContext } from "react";


export type Balance = {
    value?:number,
    currency?:string,
}


type UserBalanceProps = {
    balance?:Balance,
    setBalance:(balance:Balance)=>void
}|null

const UserBalanceContext = createContext<UserBalanceProps>(null);

export const useGetUserBalance = ()=>{

    const contenxt = useContext(UserBalanceContext);

    const balance:Balance = {
        ...contenxt?.balance
    }

    return balance;


}

export const useSetUserBalance = ()=>{
    const contenxt = useContext(UserBalanceContext);
    return (balance:Balance)=>{
     contenxt?.setBalance(balance);
    }
}

type UserBalanceProiverProps = {
    balance:UserBalanceProps,
    children?:React.ReactNode
}

const UserBalanceProiver:FC<UserBalanceProiverProps> =  ({children,balance})=>{
        return (
            <UserBalanceContext.Provider value={balance}>
                {children}
            </UserBalanceContext.Provider>
        )

}


export default UserBalanceProiver;