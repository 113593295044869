type ConfigEnv = {
  baseApi: string,
  brandId:number,
}

const configValues: any = {
  development: {
    // baseApi: '//localhost:5302',
    baseApi: "//api.uf88.xyz",
    brandId:2,
  },
  production: {
    baseApi: '//api.kabank.cc',
  },
}

const env: ConfigEnv = configValues[`${process.env.NODE_ENV}`]

export default env
