import { List, Tabs } from "antd-mobile"
import { Tab } from "antd-mobile/es/components/tabs/tabs";
import { time } from "console";
import { title } from "process";




export default () => {

    const arrs = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26]

    const tabs = [
        {title:"All"},
        {title:"Bingo"},
        {title:"Slot"},
        {title:"Fish"},
        {title:"Live"},
        {title:"POKER"},
        {title:"RNG"},
        {title:"Mini"}
    ]
    return (
        <div>
            <Tabs style={{position:"sticky"}} >
                {tabs.map((v,i)=>{
                    return (
                        <Tabs.Tab key={i} title={v.title}>
                            <h1>{v.title}</h1>
                            <List>
                                {arrs.map(s=>{
                                    return (
                                        <List.Item key={s}>{v.title}-{s}</List.Item>
                                    )
                                })}
                            </List>
                        </Tabs.Tab>
                    )
                })}
            </Tabs>

        </div>
    )
}