// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.top {
  flex: 0 1;
  border-bottom: solid 1px var(--adm-color-border);
}

.body {
  flex: 1 1;
  overflow-y: auto;
}

.bottom {
  width: 100%;
  flex: 0 1;
  border-top: solid 1px var(--adm-color-border);
  position: fixed;
  bottom: 0px;
  background-color: #1a1a1a;
  padding-bottom: 12px;
}

.top-back {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/layout/h5-layout.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEE;EACE,SAAA;EACA,gDAAA;AACJ;;AAKE;EACE,SAAA;EAIA,gBAAA;AALJ;;AAWE;EACE,WAAA;EACA,SAAA;EACA,6CAAA;EAEA,eAAA;EACA,WAAA;EACA,yBAAA;EACA,oBAAA;AATJ;;AAcE;EACE,aAAA;EACA,mBAAA;AAXJ","sourcesContent":[".app {\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .top {\n    flex: 0;\n    border-bottom: solid 1px var(--adm-color-border);\n\n\n\n  }\n  \n  .body {\n    flex: 1;\n    // display: flex;\n    // justify-content: center;\n    // align-items: center;\n    overflow-y: auto;\n    // margin-top: 46px;\n    // margin-bottom: 46px;\n    // height: calc(100%-90);\n  }\n  \n  .bottom {\n    width: 100%;\n    flex: 0;\n    border-top: solid 1px var(--adm-color-border);\n    // background-color: #000;\n    position: fixed;\n    bottom: 0px;\n    background-color: #1a1a1a;\n    padding-bottom: 12px;\n\n\n  }\n\n  .top-back{\n    display: flex;\n    align-items: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
