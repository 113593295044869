import React, { createContext, FC, useContext } from "react";

export type Config = {
   name:string,
   logo:string,
   siteUrl:string,
   siteLogo:string,
   currency:string,
}

type SietConfigProps = {
    config:Config,
    setConfig:(config:Config|any)=>void
}|null

const SiteConfigContext = createContext<SietConfigProps>(null);

export const useGetSiteConfig = ()=>{

    const contenxt = useContext(SiteConfigContext);

    return contenxt?.config;

}

export const useSetSiteConfig = ()=>{
    const contenxt = useContext(SiteConfigContext);
    return (values:any|Config)=>{
     contenxt?.setConfig(values);
    }
}

type SiteConfigProiverProps = {
    siteConfig:SietConfigProps,
    children?:React.ReactNode
}

const SiteConfigProiver:FC<SiteConfigProiverProps> =  ({children,siteConfig})=>{
        return (
            <SiteConfigContext.Provider value={siteConfig}>
                {children}
            </SiteConfigContext.Provider>
        )

}


export default SiteConfigProiver;