import React, { createContext, FC, useContext } from "react";


type Login = {
    
    user?:any
    login?:boolean
}


type LoginProps = {
    login:Login,
    setLogin:(login:Login)=>void
}|null

const LoginContext = createContext<LoginProps>(null);

export const useGetLogin = ()=>{

    const contenxt = useContext(LoginContext);

    const login:Login = {
        ...contenxt?.login
    }
    return login

}

export const useSetLogin = ()=>{
    const contenxt = useContext(LoginContext);
    return (login:Login)=>{
     contenxt?.setLogin(login);
    }
}

type LoginProiverProps = {
    value:LoginProps,
    children?:React.ReactNode
}

const LoginProiver:FC<LoginProiverProps> =  ({children,value})=>{
        return (
            <LoginContext.Provider value={value}>
                {children}
            </LoginContext.Provider>
        )

}


export default LoginProiver;