import { FC, useEffect } from 'react';
import './h5-layout.scss';
import {
    AppOutline,
    MessageOutline,
    UnorderedListOutline,
    UserOutline,

} from 'antd-mobile-icons'
import { Button, NavBar, Space, TabBar } from 'antd-mobile';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetSiteConfig, useSetSiteConfig } from '../public/SiteConfigProvier';
import { Balance, useGetUserBalance } from '../public/UserBalanceProvier';
import { useGetLogin } from '../public/LoginProvier';


const Bottom: FC = () => {

    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();


    const setRouteActive = (value: string) => {

        navigate(value);

    }

    const tabs = [
        {
            key: '/h5/home',
            title: '首页',
            icon: <AppOutline />,
        },
        {
            key: '/h5/games',
            title: '游戏',
            icon: <UnorderedListOutline />,
        },
        {
            key: '/h5/deposit',
            title: '充值',
            icon: <MessageOutline />,
        },
        {
            key: '/h5/me',
            title: '我的',
            icon: <UserOutline />,
        },
    ]

    return (
        <TabBar defaultActiveKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )
}


export default (props: any) => {

    const config = useGetSiteConfig();

    // console.log("config=>", config);

    const { value, currency } = useGetUserBalance();

    const { login, user } = useGetLogin();

    // console.log("value=>",value);


    return (
        <div className={"app"}>

            <div className={"top"}>
                <NavBar
                    backIcon={null}

                    back={
                        <div className='top-back'>
                            <UnorderedListOutline style={{ fontSize: 20 }} />
                            <img style={{ width: 80 }} src={config?.logo} />
                        </div>
                    }
                    right={<div>

                        {login ? (<>

                            <div>
                                {value} {currency}
                            </div>
                            <div>
                                <UserOutline />
                            </div>
                        </>) : (<>

                            <Space>


                                <Button size="mini" color="default" onClick={() => {

                                }} >登陆</Button>
                                <Button size="mini" color="primary" onClick={() => {

                                }} >注册</Button>
                            </Space>

                        </>)}



                    </div>}
                ></NavBar>
            </div>
            <div className={"body"}>
                {props.children}
                <div style={{height:45}}></div>
            </div>
            <div className={"bottom"}>
                <Bottom />
            </div>
        </div>
    )

}