import { FC, useEffect, useState } from "react"
import api from "../config/api"
import SiteConfigProiver, { Config } from "../public/SiteConfigProvier";
import Loading from "../public/Loading";
import UserBalanceProiver, { Balance } from "../public/UserBalanceProvier";

type UserBalanceInitProps = {
    config:Config
    children?:any
}

const UserBalanceInit:FC<UserBalanceInitProps> = ({config,children}) => {

    const [balance,setBalance] = useState<Balance>({value:0,currency:config.currency});

    return (
        <>
          <UserBalanceProiver balance={{balance:balance,setBalance:value=>{
                setBalance(value);
          }}} >
                {children}
          </UserBalanceProiver>
        </>
    )
}

export default (props: any) => {


    const [config, setConfig] = useState<any>(null);

    const [loading, setLoading] = useState(true);

    const loadConfig = () => {

        setLoading(true);

        api("site/config").get({}).then(ret => {

            if (ret.code == 200) {
                setConfig(ret.data);
                // document.title = ret.data?.name;
            }

        }).finally(() => {

            setLoading(false);
        })
    }


    useEffect(() => {

        loadConfig();


    }, []);


    if (loading) {

        return (
            <Loading />
        )
    }




    return (
        <>
            <SiteConfigProiver
                siteConfig={{
                    config: config,
                    setConfig(config) {
                        setConfig(config);
                    },
                }}
            >
                < UserBalanceInit config={config}>
                    {props.children}
                </UserBalanceInit>
            </SiteConfigProiver>


        </>
    )


}